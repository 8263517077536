'use strict';
jQuery(document).ready(function($){

    function load_js()
    {
       var head= document.getElementsByTagName('head')[0];
       var script= document.createElement('script');
       script.src= './assets/js/dist/script.min.js';
       head.appendChild(script);
    }

      //CONFIRM PARTY
      $('#add-participant').on('click', function(){
        $('#participant-list').append(`
            <div class="flex lg:pl-6 justify-center flex-wrap mt-2 participant">
                <input type="text" required name="party_partner_firstname" class="firstname appearance-none mb-2 lg:mb-0 shadow-sm relative block w-full lg:w-5/12 px-3 py-2 border border-gray-300 placeholder-black text-black lg:rounded-tr-none lg:rounded-br-none  focus:outline-none focus:ring-red-700 focus:border-red-700 focus:z-10 sm:text-sm font-light" placeholder="First name">
                <input type="text" required name="party_partner_lastname"  class="lastname appearance-none shadow-sm relative block w-full lg:w-5/12 px-3 py-2 border border-gray-300 placeholder-black text-black lg:rounded-tl-none lg:rounded-bl-none focus:outline-none focus:ring-red-700 focus:border-red-700 focus:z-10 sm:text-sm font-light" placeholder="Last name"> 
                <svg xmlns="http://www.w3.org/2000/svg" class="remove-participant self-center cursor-pointer mt-2 lg:mt-0 h-7 lg:h-5 w-full lg:w-1/12 text-red-600 hover:text-red-900" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </div>`);
         $('#participant-list .remove-participant').on('click', function(){
            $(this).parent().remove();
            if($('#participant-list div').length > 1){
              $('#add-partner').hide();
             } else {
              $('#add-partner').show();
             }
          });
          if($('#participant-list div').length > 1){
            $('#add-partner').hide();
           } else {
            $('#add-partner').show();
           }
      });

      //HOTEL FORM
        //CONFIRM PARTY
        $('#add-hotel-participant').on('click', function(){
          $('#hotel-list').append(`
              <div class="flex lg:pl-6 justify-center flex-wrap mt-2 participant">
                  <input type="text" required name="hotel_partner_firstname" class="firstname appearance-none mb-2 lg:mb-0 shadow-sm relative block w-full lg:w-5/12 px-3 py-2 border border-gray-300 placeholder-black text-black lg:rounded-tr-none lg:rounded-br-none  focus:outline-none focus:ring-red-700 focus:border-red-700 focus:z-10 sm:text-sm font-light" placeholder="First name">
                  <input type="text" required name="hotel_partner_lastname" class="lastname appearance-none shadow-sm relative block w-full lg:w-5/12 px-3 py-2 border border-gray-300 placeholder-black text-black lg:rounded-tl-none lg:rounded-bl-none focus:outline-none focus:ring-red-700 focus:border-red-700 focus:z-10 sm:text-sm font-light" placeholder="Last name"> 
                  <svg xmlns="http://www.w3.org/2000/svg" class="remove-participant self-center cursor-pointer mt-2 lg:mt-0 h-7 lg:h-5 w-full lg:w-1/12 text-red-600 hover:text-red-900" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
              </div>`);
           $('#hotel-list .remove-participant').on('click', function(){
              $(this).parent().remove();
              if($('#hotel-list div').length > 1){
                $('#add-hotel-partner').hide();
               } else {
                $('#add-hotel-partner').show();
               }
            });
            if($('#hotel-list div').length > 1){
              $('#add-hotel-partner').hide();
             } else {
              $('#add-hotel-partner').show();
             }
        });
     
      $('#register_form')
      // to prevent form from submitting upon successful validation
      .on("submit", function(ev) {
          ev.preventDefault();
          $('#success-message').hide();
          $('#error-message').text('');
          $('#register_form button').attr('disabled', 'true');
          var that = $('#register_form'),
          url = that.attr("action"),
          method = that.attr("method"),
          data = {};

          data.party_partner_firstname = '';
          data.party_partner_lastname = '';

          data.hotel_partner_firstname = '';
          data.hotel_partner_lastname = '';

          that.find("[name]").each( function() {
              var that = $(this),
                  name = that.attr("name"),
                  value = that.val();
                  data[name] = value;
          });


          $.ajax({
              url: url,
              type: method,
              data: data,
              success: function(response) {
                  if(response == 'success') {
                    $('#success-message').show();
                    $('#register_form').hide();
                  } else {
                    $('#register_form button').attr('disabled', 'false');
                    $('#error-message').text('Failed, please try again.');
                  }
                 

              },
              error: function(response){
                $('#register_form button').attr('disabled', 'false');
                $('#error-message').text('Failed, please try again.');
                console.log(response);
              }

          });
      });

      //expanded
      $('#toggle-expanded').on('click', function(){
        $('#intro-section').addClass('hidden');
        $('#expanded-section').removeClass('hidden');
      });
    });